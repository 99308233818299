//--------------------------------------------------------------
// EQUIPMENT RATES LOCATIONS
//--------------------------------------------------------------

.equipment-rates-locations {
	display: grid;
	gap: 60px;
	margin-bottom: 60px;

	@include mq($mq-laptop) {
		grid-template-columns: 1fr 500px;
	}
}
