//--------------------------------------------------------------
// IMAGE TEXT
//--------------------------------------------------------------

.image-text {
	@include background-image('texture-waves.jpg', $repeat: repeat, $size: initial);

	margin-bottom: 80px;

	&__container {
		align-items: center;
		display: grid;
		gap: 40px;
		padding-bottom: 40px;

		@include mq($mq-laptop) {
			grid-template-columns: 1fr 1fr;
			padding-bottom: 0;
		}
	}

	&__image {
		max-height: 535px;
		object-fit: cover;
	}

	&__content {
		max-width: 570px;

		@include mq($mq-laptop, false) {
			padding-left: $global-padding;
		}
		@include mq($mq-global-width, false) {
			padding-right: $global-padding;
		}
	}
}
