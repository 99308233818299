//--------------------------------------------------------------
// PACKAGES
//--------------------------------------------------------------

.packages {
	@include background-image('texture-waves-blue.jpg', $repeat: repeat, $size: initial);

	padding: 80px 0;

	&__details {
		display: grid;
		gap: 30px 50px;
		grid-template-columns: 1fr;
		margin-bottom: 50px;

		@include mq($mq-laptop) {
			grid-template-columns: 445px 1fr;
		}
	}

	&__heading {
		color: $color-white;
	}

	&__description {
		color: $color-white;

		@include mq($mq-tablet) {
			padding-right: 150px;
		}
	}

	&__cards {
		display: grid;
		gap: 30px;
		grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

		@include mq($mq-global-width) {
			grid-template-columns: repeat(3, 370px);
		}

		// Hide the WordPress inserted tags
		& > p,
		& > br {
			display: none;
		}
	}
}
