//--------------------------------------------------------------
// SETTINGS
//--------------------------------------------------------------

//-----------------------------------------
// Global
//-----------------------------------------
$global-width: 1210px;
$global-padding: 20px;
$global-radius: 3px;
$global-spacing: 25px;

//-----------------------------------------
// Media Queries
//-----------------------------------------
$mq-mobile-medium: 375px;
$mq-mobile-large: 425px;
$mq-tablet: 768px;
$mq-laptop: 1024px;
$mq-global-width: $global-width;
$mq-laptop-large: 1440px;

//-----------------------------------------
// Fonts
//-----------------------------------------
$font-domine: 'Domine', serif;

// Family
$font-family-body: $font-domine;
$font-family-heading: $font-domine;

// Size
$font-size-content: 18px;

// Weight
$font-weight-light: 300;
$font-weight-normal: 400; // Domine
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700; // Domine
$font-weight-black: 900;

//-----------------------------------------
// Colors
//-----------------------------------------
// Neutrals
$color-black: #000000;
$color-white: #FFFFFF;
$color-gray: #808080;

// Theme Colors
$color-cerulean: #00AEDB;
$color-cinnabar: #EA5A3D;
$color-mine-shaft: #222222;
$color-pacific-blue: #019CC4; // Hover primary.
$color-thunderbird: #DE3918; // Hover secondary.
$color-wild-sand: #F4F4F4;
$color-mercury: #E5E5E5;
$color-meteor: #C98910;
$color-silver-chalice: #A8A8A8;
$color-potters-clay: #965A38;

// Roles
$color-primary: $color-cerulean;
$color-secondary: $color-cinnabar;
$color-tertiary: $color-mine-shaft;
$color-primary-hover: $color-pacific-blue;
$color-secondary-hover: $color-thunderbird;
$color-theme-black: $color-mine-shaft;
$color-theme-white: #FEFEFE;
$color-theme-gray: $color-wild-sand;
$color-gold: $color-meteor;
$color-silver: $color-silver-chalice;
$color-bronze: $color-potters-clay;
$color-junior: $color-mine-shaft;

// Alerts
$color-error: #EA595E;
$color-warning: #F0BD2B;
$color-success: #59EA9D;

// Social Media - https://www.lockedownseo.com/social-media-colors/
$color-facebook: #3B5998;
$color-twitter: #1DA1F2;
$color-youtube: #FF0000;
$color-instagram: #C32AA3;
